import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/core/guards/auth.guard';
import { LocalizationService } from './core/core/services/localization.service';

const routes: Routes = [
    {
        path: 'auth/:type',
        title: () => {
            const localizationService = inject(LocalizationService);
            return localizationService.pageAuth;
        },
        loadComponent: () =>
            import('./features/auth/pages/index/index.component').then(
                (c) => c.IndexComponent
            ),
    },
    {
        path: 'auth/verified',
        loadComponent: () =>
            import('./features/auth/pages/verified/verified.component').then(
                (c) => c.VerifiedComponent
            ),
    },
    {
        path: 'app',
        canActivate: [authGuard],
        loadChildren: () =>
            import('./features/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            ),
    },
    {
        path: '',
        loadChildren: () =>
            import('./features/landing/landing.module').then(
                (m) => m.LandingModule
            ),
    },
    {
        path: 'wrong-turn',
        loadComponent() {
            return import(
                './core/core/pages/not-found-page/not-found-page.component'
            ).then((x) => x.NotFoundPageComponent);
        },
    },
    {
        path: '**',
        redirectTo: 'wrong-turn',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
