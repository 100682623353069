import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { LocalizationService } from "../services/localization.service";
import { environment } from "src/environments/environment";
import { isPlatformBrowser } from "@angular/common";

declare let gtag: Function;

@Injectable()
export class AppTitlePrefix extends TitleStrategy {

  constructor(
    private readonly _title: Title,
    private readonly _localizationService: LocalizationService,
    @Inject(LOCALE_ID) private readonly _locale: string,
    @Inject(PLATFORM_ID) private readonly _platformId: Object
  ) {
    super();
  }

  /** Adds a brand prefix to the title. */
  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (!title) return;
    const prefixedTitle = `${this._localizationService.appBrand} - ${title}`;
    this._title.setTitle(prefixedTitle);

    /** Analytics data will be provided only in production env. */
    if (environment.isProduction && isPlatformBrowser(this._platformId)) {
      /** Updating path for Analytics. */
      /** TODO: Consider injecting this Analytics ID in the future. */
      gtag("config", "G-BCSY7K8GZZ", {
        'page_title': prefixedTitle,
        'page_path': `${this._locale}${snapshot.url}`
      });
    }
  }

}
