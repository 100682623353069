import { Observable, take } from 'rxjs';
import { RouteService } from './route-service.abstract';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, PLATFORM_ID, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { QueryListWrapperRouteQuery_ } from 'src/app/core/model/queryListWrapperRouteQuery_';
import { RouteCreateCommand } from 'src/app/core/model/routeCreateCommand';
import { RouteQuery } from 'src/app/core/model/routeQuery';
import { RouteRegistrationCreateCommand } from 'src/app/core/model/routeRegistrationCreateCommand';
import { RouteFiltersWrapper } from 'src/app/core/model/routeFiltersWrapper';
import { RouteUnregistrationCreateCommand } from 'src/app/core/model/routeUnregistrationCreateCommand';
import { RouteCreateCommandWrapper } from 'src/app/core/model/routeCreateCommandWrapper';
import { isPlatformBrowser } from '@angular/common';
import { StationType } from 'src/app/core/model/stationType';
import { CountryQuery } from 'src/app/core/model/countryQuery';
import { RoutePropertyCategoryWrapper } from 'src/app/core/model/routePropertyCategoryWrapper';
import { UserPublicQuery } from 'src/app/core/model/userPublicQuery';
import { DomSanitizer } from '@angular/platform-browser';
import { StationQuery } from 'src/app/core/model/stationQuery';
import { RouteEventStatus } from '../enums/route-event-status';
import { RouteStatisticsQuery } from 'src/app/core/model/routeStatisticsQuery';
import { GeneralResponse } from 'src/app/core/model/generalResponse';

export class RouteProductionService extends RouteService {
    /** Service dependencies. */
    private readonly _http: HttpClient = inject(HttpClient);

    private readonly apiUrl = environment.apiUrl;

    constructor(
        @Inject(PLATFORM_ID) private readonly _platformId: Object,
        private readonly _sanitizer: DomSanitizer
    ) {
        super();
    }

    public override createRoute(
        route: RouteCreateCommandWrapper
    ): Observable<RouteCreateCommand> {
        return this._http.post<RouteCreateCommand>(
            `${this.apiUrl}/v1/routes`,
            route,
            { withCredentials: true }
        );
    }

    public override getRouteById(routeUuid: string): Observable<RouteQuery> {
        return this._http.get<RouteQuery>(
            `${this.apiUrl}/v1/routes/${routeUuid}`,
            { withCredentials: true }
        );
    }

    public override getRoutes(
        filters: RouteFiltersWrapper
    ): Observable<QueryListWrapperRouteQuery_> {
        // let params: HttpParams = new HttpParams();
        // for (const [key, value] of Object.entries(filters)) { if (value) params = params.set(key, value) }
        return this._http.post<QueryListWrapperRouteQuery_>(
            `${this.apiUrl}/v1/routes/filters`,
            filters
        );
    }

    public override registerRoute(
        routeUUID: string,
        startStationId: number,
        destinationStationId: number
    ): Observable<RouteQuery> {
        const routeRegistrationDto: RouteRegistrationCreateCommand = {
            route_uuid: routeUUID,
            start_station_id: startStationId,
            destination_station_id: destinationStationId,
        };
        return this._http.post<RouteQuery>(
            `${this.apiUrl}/v1/routes/register`,
            routeRegistrationDto,
            { withCredentials: true }
        );
    }

    public override unregisterRoute(routeUUID: string): Observable<boolean> {
        const routeUnregistrationDto: RouteUnregistrationCreateCommand = {
            route_uuid: routeUUID,
        };
        return this._http.post<boolean>(
            `${this.apiUrl}/v1/routes/unregister`,
            routeUnregistrationDto,
            { withCredentials: true }
        );
    }

    public override getProperties(
        languageId: number = 1
    ): Observable<RoutePropertyCategoryWrapper> {
        return this._http.get<RoutePropertyCategoryWrapper>(
            `${this.apiUrl}/v1/routes/properties`,
            { params: new HttpParams().set('language_id', languageId) }
        );
    }

    /**
     * It's used to set the default country based on the app's locale for the route country filter input.
     * @param countries The list of available countries.
     * @returns The standard name of the counry.
     */
    public override setInitialCountry(countries: CountryQuery[]): string {
        const DEFAULT_COUNTRY: string =
            this.localizationService.allFilterValues;
        // Uses the browser's navigator API to get the user's preferred language.
        let browserLanguage = isPlatformBrowser(
            this.configService.getPlatform()
        )
            ? navigator.language
            : DEFAULT_COUNTRY;

        if (browserLanguage.includes('-'))
            browserLanguage = browserLanguage.split('-').at(1).toLowerCase();

        // As US browser language can be used by non American users as well, we return the default option...
        if (browserLanguage === 'us') return DEFAULT_COUNTRY;

        // Tries to use the user's preferred language if it's available, otherwise the default one will be returned.
        const country: CountryQuery = countries.find(
            (country: CountryQuery) =>
                country.alpha_2.toLowerCase() === browserLanguage
        );

        return (country && country.name) ?? DEFAULT_COUNTRY;
    }

    public override getRouteParticipants(
        routeId: number,
        stationId: number
    ): Observable<UserPublicQuery[]> {
        return this._http.post<UserPublicQuery[]>(
            `${environment.apiUrl}/v1/routes/participants`,
            {},
            {
                params: new HttpParams()
                    .set('route_id', routeId)
                    .set('station_id', stationId),
            }
        );
    }

    public override constructMapsUrl(routeDetails: RouteQuery): string | null {
        // The station types.
        const STATION_TYPE_START = StationType.Start;
        const STATION_TYPE_INTERMEDIATE = StationType.Intermediate;
        const STATION_TYPE_DESTINATION = StationType.Destination;

        // If the app is running on the server side, we don't need to construct the URL.
        if (isPlatformBrowser(this._platformId)) return null;

        if (!routeDetails) return null;

        const isIntermediateStationAvailable: StationQuery | undefined =
            routeDetails.stations.find(
                (station: StationQuery) =>
                    station.type.type === STATION_TYPE_INTERMEDIATE
            );
        const waypointsSegment: string = isIntermediateStationAvailable
            ? `&waypoints=${this._getStationAddresses(
                  routeDetails,
                  STATION_TYPE_INTERMEDIATE
              )}`
            : '';
        const avoidSegment: string = !routeDetails.highway
            ? '&avoid=tolls|highways'
            : '';

        const url: string = `https://www.google.com/maps/embed/v1/directions?origin=${this._getStationAddresses(
            routeDetails,
            STATION_TYPE_START
        )}${waypointsSegment}&destination=${this._getStationAddresses(
            routeDetails,
            STATION_TYPE_DESTINATION
        )}${avoidSegment}&key=AIzaSyBivzFLrpHifcV5srctBQpYhEeAzAFBxzw`;
        return url;
    }

    protected override _getStationAddresses(
        routeDetails: RouteQuery,
        stationType: StationType
    ): string {
        const stationAddress: string[] = routeDetails.stations
            .filter(
                (station: StationQuery) => station.type.type === stationType
            )
            .map((station: StationQuery) => station.address);

        if (stationAddress.length < 1) return '';

        return stationAddress.join('|');
    }

    public override getRouteEventStatus(
        startDateTime: Date,
        finishDateTime: Date
    ): RouteEventStatus {
        const now = new Date();

        if (startDateTime > now) {
            return RouteEventStatus.UPCOMING;
        } else if (startDateTime <= now && finishDateTime >= now) {
            return RouteEventStatus.ONGOING;
        } else {
            return RouteEventStatus.FINISHED;
        }
    }

    public override getTextForRouteEventStatus(
        status: RouteEventStatus
    ): string {
        switch (status) {
            case RouteEventStatus.UPCOMING:
                return this.localizationService.txtRouteEventUpcoming;
            case RouteEventStatus.ONGOING:
                return this.localizationService.txtRouteEventOngoing;
            case RouteEventStatus.FINISHED:
                return this.localizationService.txtRouteEventFinished;
            default:
                return 'Unknown status';
        }
    }

    public override getRouteStations(routeData: RouteQuery): string {
        if (!routeData) throw new Error('Route data is not available.');
        const startStation = this._getStationData(
            routeData,
            this.START_STATION
        );
        const finishStation = this._getStationData(
            routeData,
            this.FINISH_STATION
        );
        if (!startStation || !finishStation) return 'unknown';
        return `${startStation.city.name.toLowerCase() ?? 'unknown'}-${
            finishStation.city.name.toLowerCase() ?? 'unknown'
        }`;
    }

    public override getRouteStartStationCountry(routeData: RouteQuery): string {
        if (!routeData) throw new Error('Route data is not available.');
        const startStation = this._getStationData(
            routeData,
            this.START_STATION
        );
        if (!startStation) return 'unknown';
        return startStation.city.country.name.toLowerCase() ?? 'unknown';
    }

    public override getRouteStatistics(): Observable<RouteStatisticsQuery> {
        return this._http.get<RouteStatisticsQuery>(
            `${this.apiUrl}/v1/routes/statistics`
        );
    }

    public override getNextWeekendDays(): { start: Date; end: Date } {
        const now = new Date();
        const currentDay = now.getUTCDay();
        const daysUntilNextSaturday = (7 - currentDay + 6) % 7;
        const daysUntilNextSunday = (7 - currentDay + 7) % 7;

        let nextSaturday: Date = new Date();

        const nextSunday = new Date(
            Date.UTC(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate() + daysUntilNextSunday
            )
        );

        // If today is Sunday, we need to set the next Saturday to the previous day.
        if (currentDay === 0) {
            nextSaturday.setUTCDate(nextSunday.getUTCDate() - 1);
        } else {
            nextSaturday = new Date(
                Date.UTC(
                    now.getUTCFullYear(),
                    now.getUTCMonth(),
                    now.getUTCDate() + daysUntilNextSaturday
                )
            );
        }

        nextSaturday.setUTCHours(0, 0, 0, 0);
        nextSunday.setUTCHours(23, 59, 59, 999);

        // TODO: Remove console logs.
        console.log(nextSaturday.toISOString());
        console.log(nextSunday.toISOString());

        return { start: nextSaturday, end: nextSunday };
    }

    public override cancelRoute(
        routeUUID: string
    ): Observable<GeneralResponse> {
        return this._http.patch<GeneralResponse>(
            `${this.apiUrl}/v1/routes/${routeUUID}/cancel`,
            {},
            { withCredentials: true }
        );
    }
}
