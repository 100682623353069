import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { AuthService } from 'src/app/features/auth/services/auth.service.abstract';
import { MatSnackBar } from '@angular/material/snack-bar';

export const authGuard: CanActivateFn = (_route, state) => {
  const authService: AuthService = inject(AuthService);
  const snackbar: MatSnackBar = inject(MatSnackBar);
  const router: Router = inject(Router);

  if (authService.activeUser) return of(true);

  const segments = state.url.split('/');
  const appSegmentIdx = segments.findIndex((segment: string) => segment === 'motorcycle-tours');

  return authService.whoami()
    .pipe(
      catchError(() => {
        // As the list of motorcycles tours are publicly visible, we need to create an URL without the 'app' prefix.
        let updatedUrl = segments.filter((segment: string) => segment != 'app')
        updatedUrl[0] = '/'
        // The requests related to the tours will be redirected to the version without the 'app' prefix.
        // Other requests will be redirected to the home in case of missing auth data.
        router.navigate(appSegmentIdx ? updatedUrl : ['/']);

        snackbar.open('Sign in to view this content.', '', { duration: 3000 })
        return of(false);
      }),
      map(() => true)
    )
};
