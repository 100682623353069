import { Observable } from 'rxjs';
import { ProfileService } from './profile-service.abstract';
import { UserUpdateCommand } from 'src/app/core/model/userUpdateCommand';
import { UserPublicQuery } from 'src/app/core/model/userPublicQuery';
import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RatingQuery } from 'src/app/core/model/ratingQuery';
import { QueryListWrapperRatingQuery } from 'src/app/core/model/queryListWrapperRatingQuery';
import { SuccessQuery } from 'src/app/core/model/successQuery';
import { UpdateRatingCommand } from 'src/app/core/model/updateRatingCommand';
import { OverallRatingQuery } from 'src/app/core/model/overallRatingQuery';

export class ProfileProductionService extends ProfileService {
    private readonly _http: HttpClient = inject(HttpClient);

    constructor() {
        super();
    }

    public override getUserByUUID(uuid: string): Observable<UserPublicQuery> {
        return this._http.get<UserPublicQuery>(
            `${environment.apiUrl}/v1/users`,
            {
                params: new HttpParams().set('user_uuid', uuid),
            }
        );
    }

    public override updateProfile(
        data: UserUpdateCommand
    ): Observable<UserPublicQuery> {
        return this._http.put<UserPublicQuery>(
            `${environment.apiUrl}/v1/users`,
            data,
            { withCredentials: true }
        );
    }

    public override hasRightToCreateRoute(userData: UserPublicQuery): boolean {
        // Certain properties are ignored when defining the right of route creation.
        let data = { ...userData };
        console.log(data);
        for (const [key, value] of Object.entries(data)) {
            if (key !== 'avatar' && !value) return false;
        }
        return true;
    }

    public override getUserRatings(
        userUUID: string,
        reviewer: boolean,
        toReview: boolean,
        participated: boolean,
        page: number
    ): Observable<QueryListWrapperRatingQuery> {
        return this._http.get<QueryListWrapperRatingQuery>(
            `${environment.apiUrl}/v1/ratings/users/${userUUID}`,
            {
                params: new HttpParams()
                    .set('is_reviewer', reviewer.toString())
                    .set('to_review', toReview.toString())
                    .set('participated', participated.toString())
                    .set('page', page.toString()),
            }
        );
    }

    public override getRating(ratingUUID: string): Observable<RatingQuery> {
        return this._http.get<RatingQuery>(
            `${environment.apiUrl}/v1/ratings/${ratingUUID}`,
            { withCredentials: true }
        );
    }

    public override updateRating(
        updateRatingDto: UpdateRatingCommand
    ): Observable<SuccessQuery> {
        return this._http.put<SuccessQuery>(
            `${environment.apiUrl}/v1/ratings/${updateRatingDto.rating_uuid}`,
            updateRatingDto,
            { withCredentials: true }
        );
    }

    public override getOverallRating(
        userUUID: string
    ): Observable<OverallRatingQuery> {
        return this._http.get<OverallRatingQuery>(
            `${environment.apiUrl}/v1/ratings/users/${userUUID}/overall`
        );
    }
}
