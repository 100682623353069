import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigurationService } from './core/core/services/configuration.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { ActivityIndicatorService } from './core/core/services/activity-indicator.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public readonly activityIndicatorService = inject(ActivityIndicatorService);

    private readonly subscriptionHandler = new Subscription();

    constructor(
        private readonly _matIconRegistry: MatIconRegistry,
        private readonly _domSanitizer: DomSanitizer,
        private readonly _configService: ConfigurationService,
        private readonly _cookieService: NgcCookieConsentService
    ) {
        this._configService.initializeCookieBanner(this._cookieService);
    }

    ngOnInit(): void {
        // TODO:
        // Find a better place to add the customer icons.

        this._matIconRegistry.addSvgIcon(
            'highway',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/images/routes/highway_icon.svg'
            )
        );

        this._matIconRegistry.addSvgIcon(
            'festival',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/images/routes/festival_icon.svg'
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptionHandler.unsubscribe();
    }
}
