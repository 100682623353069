import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './features/auth/services/auth.service.abstract';
import { AuthProductionService } from './features/auth/services/auth-production.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/core/interceptors/token.interceptor';
import { Loader } from '@googlemaps/js-api-loader';
import { RouteService } from './features/routes/services/route-service.abstract';
import { RouteProductionService } from './features/routes/services/route-production.service';
import { ProfileService } from './features/profiles/services/profile-service.abstract';
import { ProfileProductionService } from './features/profiles/services/profile-production.service';
import { isPlatformBrowser } from '@angular/common';
import { TitleStrategy } from '@angular/router';
import { AppTitlePrefix } from './core/core/classes/app-title-strategy.class';
import {
    NgcCookieConsentModule,
    NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Cookie and content will be overriden in the ConfigurationService.
const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: '',
    },
    position: 'bottom-left',
    theme: 'block',
    palette: {
        popup: {
            background: '#000000',
            text: '#ffffff',
            link: '#ffffff',
        },
        button: {
            background: '#32fa68',
            text: '#000000',
            border: 'transparent',
        },
    },
    type: 'info',
    content: {
        message:
            'This website uses cookies to ensure you get the best experience on our website.',
        dismiss: 'Got it!',
        deny: 'Refuse cookies',
        link: 'Learn more',
        href: '',
        policy: 'Cookie Policy',
    },
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (platformId: Object) => {
                /**
                 * TODO: This initialization method should be placed somewhere else.
                 */

                /**
                 * Specific initializations tasks (e.g.: loading Google Maps) will be done only,
                 * if application isn't rendered on the server.
                 */
                if (isPlatformBrowser(platformId)) {
                    const loader = new Loader({
                        apiKey: 'AIzaSyBivzFLrpHifcV5srctBQpYhEeAzAFBxzw',
                        version: 'weekly',
                        libraries: ['visualization', 'places'],
                    });
                    loader.importLibrary('visualization'),
                        loader.importLibrary('places');
                }

                return () => true;
            },
            deps: [PLATFORM_ID],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: TitleStrategy,
            useClass: AppTitlePrefix,
        },
        {
            provide: AuthService,
            useClass: AuthProductionService,
            deps: [],
        },
        {
            provide: ProfileService,
            useClass: ProfileProductionService,
            deps: [],
        },
        {
            provide: RouteService,
            useClass: RouteProductionService,
            deps: [],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
