import {
    Inject,
    Injectable,
    LOCALE_ID,
    OnDestroy,
    PLATFORM_ID,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { IMetaData } from '../types/meta-data.interface';
import { LocalizationService } from './localization.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
    BehaviorSubject,
    Observable,
    Subscription,
    distinctUntilChanged,
    filter,
    fromEvent,
    map,
    takeUntil,
} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Country } from '../../model/country';
import { UserDataVisibilityLevel } from '../../model/userDataVisibilityLevel';
import { ParticipantsNumber } from '../../model/participantsNumber';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MatDrawerMode } from '@angular/material/sidenav';
import { CountryQuery } from '../../model/countryQuery';
import { LanguageQuery } from '../../model/languageQuery';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService implements OnDestroy {
    private readonly _subscriptionHandler: Subscription = new Subscription();
    private readonly _metaDataSub: BehaviorSubject<IMetaData> =
        new BehaviorSubject<IMetaData>(null);
    /** Makes available the currently applied metadata for the components. */
    public readonly metaData$: Observable<IMetaData> =
        this._metaDataSub.asObservable();

    /** Default metadata, which sets the default values. */
    public readonly DEFAULT_METADATA: IMetaData = {
        title: this._localizationService.appBrand,
        url: `${environment.clientUrl}/${this._locale}/`,
        img: '/assets/images/social/social_post_img.jpg',
        description: this._localizationService.appMotto,
        keywords: ['roadspace', 'motorcycles', 'travelling', 'riding'],
        type: 'website',
    };

    /**
     * Configuration object for the Angular WYSIWYG Editor.
     * https://github.com/kolkov/angular-editor#readme
     */
    public readonly EDITOR_CONFIG: AngularEditorConfig = {
        editable: true,
        spellcheck: false,
        sanitize: true,
        minHeight: '200px',
        maxHeight: '400px',
        toolbarHiddenButtons: [
            [
                // 'undo',
                // 'redo',
                // 'bold',
                // 'italic',
                // 'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'justifyLeft',
                'justifyCenter',
                'justifyRight',
                'justifyFull',
                'indent',
                'outdent',
                // 'insertUnorderedList',
                // 'insertOrderedList',
                'heading',
                'fontName',
            ],
            [
                'fontSize',
                'textColor',
                'backgroundColor',
                'customClasses',
                'link',
                'unlink',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode',
            ],
        ],
    };

    /** Defines the maximum character length for the details field (events & routes). */
    public readonly MAX_DETAILS_FIELD_CHARATER = 32000;

    private readonly _sidebarMode: BehaviorSubject<MatDrawerMode> =
        new BehaviorSubject<MatDrawerMode>('over');
    /** Returns the sidebar's mode, which is set in the ConfigurationService. */
    public sidebarMode$: Observable<MatDrawerMode> =
        this._sidebarMode.asObservable();
    public get sidebarMode() {
        return this._sidebarMode.getValue();
    }
    public set sidebarMode(mode: MatDrawerMode) {
        this._sidebarMode.next(mode);
    }

    private readonly _isAppSidebarOpen: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    public isAppSidebarOpen$: Observable<boolean> =
        this._isAppSidebarOpen.asObservable();

    private readonly _isLandingSidebarOpen: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    public isLandingSidebarOpen$: Observable<boolean> =
        this._isLandingSidebarOpen.asObservable();

    /** Stores the active locale of the application.  */
    private _activeLocaleSub: BehaviorSubject<LanguageQuery> =
        new BehaviorSubject<LanguageQuery>(null);
    public readonly activeLocale$: Observable<LanguageQuery> =
        this._activeLocaleSub.asObservable();
    public get activeLocale() {
        return this._activeLocaleSub.getValue();
    }
    public set activeLocale(locale: LanguageQuery) {
        this._activeLocaleSub.next(locale);
    }

    constructor(
        private readonly _http: HttpClient,
        private readonly _router: Router,
        private readonly _metaService: Meta,
        private readonly _route: ActivatedRoute,
        private readonly _localizationService: LocalizationService,
        @Inject(LOCALE_ID) private readonly _locale: string,
        @Inject(PLATFORM_ID) private readonly _platformId: Object,
        @Inject(DOCUMENT) private readonly _doc: Document
    ) {
        this.configureSidebarBehaviour();
        this.detectUrlChange();
        this.setMetaTags(this.DEFAULT_METADATA);
        this.setMetaUrl();
    }

    ngOnDestroy(): void {
        this._subscriptionHandler.unsubscribe();
    }

    public updateMetaData(data: Partial<IMetaData>): void {
        for (const [key, value] of Object.entries(data)) {
            this._metaService.updateTag({
                name: key,
                content: value as string,
            });
            this._metaService.updateTag({
                property: `og:${key}`,
                content: value as string,
            });
        }
    }

    private setMetaTags(data: IMetaData): void {
        this._metaService.addTags([
            { name: 'title', content: data.title },
            { property: 'og:title', content: data.title },
            { name: 'url', content: data.url },
            { property: 'og:url', content: data.url },
            { name: 'image', content: data.img },
            { property: 'og:image', content: data.img },
            { name: 'description', content: data.description },
            { property: 'og:description', content: data.description },
            { name: 'keywords', content: data.keywords.join(', ') },
            { property: 'og:type', content: data.type },
        ]);
    }

    /**
     * Updates the url metadata after each navigation event.
     */
    private setMetaUrl(): void {
        this._subscriptionHandler.add(
            this._router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .pipe(distinctUntilChanged())
                .subscribe((e) => {
                    if (!e) return;
                    const metaData: IMetaData = {
                        url: `${environment.clientUrl}/${
                            this._locale
                        }${this._router.url.toString()}`,
                    };
                    this._metaDataSub.next({
                        ...this._metaDataSub.getValue(),
                        url: metaData.url,
                    });
                    this.updateMetaData(metaData);

                    /** Originally, I inserted the Google Analytics snippet here, but
                     * due to an Angular bug (namely the title attribute returned undefined),
                     * I had to move it to the AppTitlePrefix class,
                     * which is executed at every route change as well.
                     */
                })
        );
    }

    public getAppLocale(): string {
        return this._locale;
    }

    /** Returns the platform id, which is used to check whether the application is rendered client side or on the server. */
    public getPlatform(): Object {
        return this._platformId;
    }

    public getAvailableLanguages(): Observable<LanguageQuery[]> {
        return this._http.get<LanguageQuery[]>(
            `${environment.apiUrl}/v1/utilities/languages`
        );
    }

    /** Sets the active locale of the application. */
    public setActiveLanguage(): void {
        this.getAvailableLanguages().subscribe((queryRes: LanguageQuery[]) => {
            if (!queryRes) return;
            this.activeLocale =
                queryRes.find(
                    (locale: LanguageQuery) =>
                        locale.iso_identifier === this.getAppLocale()
                ) ?? null;
        });
    }

    public getAvailableCountries(): Observable<CountryQuery[]> {
        return this._http.get<CountryQuery[]>(
            `${environment.apiUrl}/v1/configuration/countries`
        );
    }

    public getUserDataVisibilityLevels(): Observable<
        UserDataVisibilityLevel[]
    > {
        return this._http.get<UserDataVisibilityLevel[]>(
            `${environment.apiUrl}/v1/configuration/user-data-visibility-levels`
        );
    }

    public getParticipantsMaxLimit(
        groupId: ParticipantsNumber
    ): Observable<number> {
        const params: HttpParams = new HttpParams().set(
            'group_id',
            groupId as number
        );
        return this._http.get<number>(
            `${environment.apiUrl}/v1/configuration/participants-max-limit`,
            { params: params }
        );
    }

    public initializeCookieBanner(
        cookieService: NgcCookieConsentService
    ): void {
        // FYI:
        // https://www.npmjs.com/package/ngx-cookieconsent?activeTab=readme

        // const cookiePolicyPath: string = /cookie-policy`

        if (isPlatformBrowser(this._platformId)) {
            cookieService.getConfig().content =
                cookieService.getConfig().content || {};
            // Override default messages with the translated ones.
            cookieService.getConfig().cookie.domain = environment.isProduction
                ? environment.clientUrl.replace(/^https?:\/\//, '')
                : 'localhost';
            cookieService.getConfig().content.message =
                this._localizationService.txtLandingCookieMessage;
            cookieService.getConfig().content.dismiss =
                this._localizationService.txtLandingCookieDismiss;
            cookieService.getConfig().content.allow =
                this._localizationService.txtLandingCookieDismiss;
            cookieService.getConfig().content.deny =
                this._localizationService.txtLandingCookieDeny;
            cookieService.getConfig().content.link =
                this._localizationService.txtLandingCookieLearnMorePolicy;
            cookieService.getConfig().content.href = `${
                environment.clientUrl
            }/${
                environment.isProduction ? this.getAppLocale() + '/' : ''
            }cookie-policy`;
            cookieService.getConfig().content.policy = 'Cookie Policy';
            // Removes previous cookie bar (with default messages).
            cookieService.destroy();
            // Updates config with translated messages.
            cookieService.init(cookieService.getConfig());
        }
    }

    private configureSidebarBehaviour(): void {
        let windowWidth: number = isPlatformBrowser(this._platformId)
            ? window.innerWidth
            : 820;

        if (isPlatformBrowser(this._platformId)) {
            /** Instead of using a HostListener on the method, because of the SSR issues. */
            fromEvent(window, 'resize').subscribe((_event: Event) => {
                windowWidth = window.innerWidth;
                if (windowWidth < 768) {
                    this._sidebarMode.next('over');
                    return;
                } else {
                    this._sidebarMode.next('side');
                    this._isLandingSidebarOpen.next(false);
                    return;
                }
            });
        }

        if (windowWidth < 768) {
            this._sidebarMode.next('over');
            return;
        }

        this._sidebarMode.next('side');
        this._isAppSidebarOpen.next(true);
    }

    private detectUrlChange(): void {
        this._subscriptionHandler.add(
            this._router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .pipe(distinctUntilChanged())
                .subscribe((e) => {
                    if (!e) return;
                    this.destroyCanonicalUrls();
                })
        );
    }

    /** Sets the canonical URL. */
    public setCanonicalUrl(url: string): void {
        if (isPlatformBrowser(this._platformId) && url) {
            const link: HTMLLinkElement = this._doc.createElement('link');
            link.setAttribute('rel', 'canonical');
            this._doc.head.appendChild(link);
            link.setAttribute('href', url);
        }
    }

    /** Removes the unused canonical URLs. */
    private destroyCanonicalUrls(): void {
        if (isPlatformBrowser(this._platformId)) {
            const els = this._doc.querySelectorAll("link[rel='canonical']");

            if (els.length === 0) return;

            els.forEach((el: Element) => {
                el.remove();
            });
        }
    }

    public getBrowserLanguage(): string {
        return navigator.language;
    }
}
