import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Login } from 'src/app/core/model/login';
import { UserCreateCommand } from 'src/app/core/model/userCreateCommand';
import { UserOut } from 'src/app/core/model/userOut';
import { UserPublicQuery } from 'src/app/core/model/userPublicQuery';
import { environment } from 'src/environments/environment';

export abstract class AuthService {
    /** Service dependencies. */
    protected readonly _router: Router = inject(Router);
    protected readonly _snackbar: MatSnackBar = inject(MatSnackBar);

    /** Backend base url. */
    protected readonly _apiUrl: string;
    protected activeUserSub: BehaviorSubject<UserPublicQuery | null> =
        new BehaviorSubject<UserPublicQuery | null>(null);
    public activeUser$: Observable<UserPublicQuery | null> =
        this.activeUserSub.asObservable();
    public get activeUser(): UserPublicQuery {
        return this.activeUserSub.getValue();
    }
    public set activeUser(data: UserPublicQuery) {
        this.activeUserSub.next(data);
    }
    /** Returns the active user's data. */
    public abstract whoami(): Observable<UserPublicQuery>;
    /** Handles login. */
    public abstract login(
        email: string,
        password: string
    ): Observable<UserPublicQuery>;
    /** Handles logout. */
    public abstract logout(): void;
    public abstract refreshAccessToken(): Observable<any>;
    public abstract signup(
        signupData: UserCreateCommand
    ): Observable<UserPublicQuery>;
    public abstract verifyEmail(
        userUuid: string,
        token: string
    ): Observable<any>;

    constructor() {
        this._apiUrl = environment.apiUrl;
    }

    /** Updates the state and directs the user in case of a successful login. */
    public handleSuccessfulLogin(activeUserData: UserPublicQuery): void {
        if (!activeUserData) return;
        this.activeUserSub.next(activeUserData);
        this._router.navigate(['app']);
    }
}
