import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActivityIndicatorService {
    /** Stores, whether the activity indicator spinner should be visible or not. */
    private readonly _isActive: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    public readonly isActive$: Observable<boolean> =
        this._isActive.asObservable();
    public set isActive(isActive: boolean) {
        this._isActive.next(isActive);
    }

    constructor() {}
}
