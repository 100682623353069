import { inject } from '@angular/core';
import { AuthService } from './auth.service.abstract';
import { Observable, first, tap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserOut } from 'src/app/core/model/userOut';
import { Login } from 'src/app/core/model/login';
import { UserPublicQuery } from 'src/app/core/model/userPublicQuery';
import { UserCreateCommand } from 'src/app/core/model/userCreateCommand';
import { ConfigurationService } from 'src/app/core/core/services/configuration.service';

export class AuthProductionService extends AuthService {
    private readonly _http: HttpClient = inject(HttpClient);
    private readonly _configService = inject(ConfigurationService);

    constructor() {
        super();
    }

    public override whoami(): Observable<UserPublicQuery> {
        return this._http
            .get<UserPublicQuery>(`${this._apiUrl}/whoami`, {
                withCredentials: true,
            })
            .pipe(
                first(),
                tap((activeUserRes: UserPublicQuery) => {
                    if (!activeUserRes) return;
                    this.activeUserSub.next(activeUserRes);
                })
            );
    }

    public override login(email: string, password: string): Observable<any> {
        const credentials = { email, password };
        return this._http.post<any>(
            `${this._apiUrl}/auth/basic/login`,
            credentials,
            { withCredentials: true }
        );
    }

    public override logout(): void {
        this._http
            .get<any>(`${this._apiUrl}/auth/basic/logout`, {
                withCredentials: true,
            })
            .subscribe((res: any) => {
                this.activeUserSub.next(null);
                this._snackbar.open(`Logout was successful!`, '', {
                    duration: 3000,
                });
                this._router.navigate(['/']);
            });
    }

    public override refreshAccessToken(): Observable<any> {
        return this._http.get<any>(`${this._apiUrl}/auth/basic/refresh`, {
            withCredentials: true,
        });
    }

    public override signup(
        signupData: UserCreateCommand
    ): Observable<UserPublicQuery> {
        const appLocale = this._configService.getAppLocale();
        return this._http.post<UserPublicQuery>(
            `${this._apiUrl}/auth/basic/signup`,
            signupData,
            { params: new HttpParams().set('language_identifier', appLocale) }
        );
    }

    public override verifyEmail(
        userUuid: string,
        token: string
    ): Observable<any> {
        return this._http.get(`${this._apiUrl}/auth/basic/verify`, {
            params: new HttpParams()
                .set('user_id', userUuid)
                .set('token', token),
        });
    }
}
