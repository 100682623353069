import { Observable } from 'rxjs';
import { OverallRatingQuery } from 'src/app/core/model/overallRatingQuery';
import { QueryListWrapperRatingQuery } from 'src/app/core/model/queryListWrapperRatingQuery';
import { RatingQuery } from 'src/app/core/model/ratingQuery';
import { SuccessQuery } from 'src/app/core/model/successQuery';
import { UpdateRatingCommand } from 'src/app/core/model/updateRatingCommand';
import { UserPublicQuery } from 'src/app/core/model/userPublicQuery';
import { UserUpdateCommand } from 'src/app/core/model/userUpdateCommand';

export abstract class ProfileService {
    public abstract getUserByUUID(uuid: string): Observable<UserPublicQuery>;
    public abstract updateProfile(
        data: UserUpdateCommand
    ): Observable<UserPublicQuery>;
    /** Checks whether the user is allowed to create a new route. */
    public abstract hasRightToCreateRoute(userData: UserPublicQuery): boolean;

    public abstract getUserRatings(
        userUUID: string,
        reviewer: boolean,
        toReview: boolean,
        participated: boolean,
        page: number
    ): Observable<QueryListWrapperRatingQuery>;

    public abstract getRating(ratingUUID: string): Observable<RatingQuery>;

    public abstract updateRating(
        updateRatingDto: UpdateRatingCommand
    ): Observable<SuccessQuery>;

    public abstract getOverallRating(
        userUUID: string
    ): Observable<OverallRatingQuery>;
}
