import { inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalizationService } from 'src/app/core/core/services/localization.service';
import { QueryListWrapperRouteQuery_ } from 'src/app/core/model/queryListWrapperRouteQuery_';
import { RouteCreateCommand } from 'src/app/core/model/routeCreateCommand';
import { RouteCreateCommandWrapper } from 'src/app/core/model/routeCreateCommandWrapper';
import { RouteFilters } from 'src/app/core/model/routeFilters';
import { RouteFiltersWrapper } from 'src/app/core/model/routeFiltersWrapper';
import { RouteQuery } from 'src/app/core/model/routeQuery';
import { ICharacteristic } from '../types/characteristic.interface';
import { ConfigurationService } from 'src/app/core/core/services/configuration.service';
import { CountryQuery } from 'src/app/core/model/countryQuery';
import { RoutePropertyCategoryWrapper } from 'src/app/core/model/routePropertyCategoryWrapper';
import { UserPublicQuery } from 'src/app/core/model/userPublicQuery';
import { SafeResourceUrl } from '@angular/platform-browser';
import { StationType } from 'src/app/core/model/stationType';
import { RouteEventStatus } from '../enums/route-event-status';
import { StationQuery } from 'src/app/core/model/stationQuery';
import { UpdateRatingCommand } from 'src/app/core/model/updateRatingCommand';
import { SuccessQuery } from 'src/app/core/model/successQuery';
import { RouteStatisticsQuery } from 'src/app/core/model/routeStatisticsQuery';
import { GeneralResponse } from 'src/app/core/model/generalResponse';

export abstract class RouteService {
    protected readonly localizationService = inject(LocalizationService);
    protected readonly configService = inject(ConfigurationService);

    public readonly START_STATION: StationType = StationType.Start;
    public readonly INTERMEDIATE_STATION: StationType =
        StationType.Intermediate;
    public readonly FINISH_STATION: StationType = StationType.Destination;

    private readonly defaultFilterOptionsSub: BehaviorSubject<RouteFilters> =
        new BehaviorSubject<RouteFilters>({
            country_id: 0,
            user_coord_lat: 0,
            user_coord_long: 0,
            distance_radius: 0,
            city: '',
            start_date: '',
            finish_date: '',
            only_free: false,
            only_reviewed: false,
            properties: [],
        });
    /** Returns the default configuration of route filters. */
    public get defaultFilterOptions(): RouteFilters {
        return this.defaultFilterOptionsSub.getValue();
    }

    protected readonly routesSub: BehaviorSubject<any[]> = new BehaviorSubject<
        any[]
    >(['']);
    public readonly routes$: Observable<any[]> = this.routesSub.asObservable();

    public abstract createRoute(
        route: RouteCreateCommandWrapper
    ): Observable<RouteCreateCommand>;
    public abstract getRoutes(
        filters: RouteFiltersWrapper
    ): Observable<QueryListWrapperRouteQuery_>;
    /** Returns a route with the given UUID. */
    public abstract getRouteById(routeUuid: string): Observable<RouteQuery>;
    public abstract registerRoute(
        routeUUID: string,
        startStationId: number,
        destinationStationId: number
    ): Observable<RouteQuery>;
    public abstract unregisterRoute(routeUUID: string): Observable<boolean>;
    /** Returns the available filter options.  */
    public abstract getProperties(
        languageId: number
    ): Observable<RoutePropertyCategoryWrapper>;

    public abstract setInitialCountry(filters: CountryQuery[]): string;

    public abstract getRouteParticipants(
        routeId: number,
        stationId: number
    ): Observable<UserPublicQuery[]>;

    /**
     * Constructs the Google Maps URL for the route list and route details page.
     * @param routeDetails The route details.
     * @returns The constructed Google Maps URL as a string, or null if it cannot be constructed.
     */
    public abstract constructMapsUrl(routeDetails: RouteQuery): string | null;

    /** Returns the stations' addresses of given station type. */
    protected abstract _getStationAddresses(
        routeDetails: RouteQuery,
        stationType: StationType
    ): string;

    /**
     * Determines the status of a route event based on the start and finish date times.
     * @param startDateTime The start date and time of the route event.
     * @param finishDateTime The finish date and time of the route event.
     * @returns The status of the route event.
     */
    public abstract getRouteEventStatus(
        startDateTime: Date,
        finishDateTime: Date
    ): RouteEventStatus;

    /**
     * Returns the text representation of the route event status.
     * @param status The status of the route event.
     * @returns The text representation of the route event status.
     */
    public abstract getTextForRouteEventStatus(
        status: RouteEventStatus
    ): string;

    /**
     * Retrieves the route stations based on the provided route data.
     *
     * @param routeData - The route data containing information about the stations.
     * @returns A string representing the route stations in the format "startCity-finishCity".
     * @throws Error if routeData is not available.
     */
    public abstract getRouteStations(routeData: RouteQuery): string;

    /**
     * Retrieves the country of the start station for a given route.
     * If the start station is not found or the country is not available, 'unknown' is returned.
     *
     * @param routeData - The route data containing information about the route.
     * @returns The country of the start station or 'unknown' if not found.
     */
    public abstract getRouteStartStationCountry(routeData: RouteQuery): string;

    /**
     * Returns the station data of a specific type in a route object.
     * @param routeData
     * @param stationType
     * @returns
     */
    protected _getStationData(
        routeData: RouteQuery,
        stationType: StationType
    ): StationQuery | undefined {
        return routeData.stations.find(
            (station: StationQuery) => station.type.type === stationType
        );
    }

    public abstract getRouteStatistics(): Observable<RouteStatisticsQuery>;

    public abstract getNextWeekendDays(): { start: Date; end: Date };

    public abstract cancelRoute(routeUUID: string): Observable<GeneralResponse>;
}
